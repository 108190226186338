import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { BsCheckCircle, BsFillPlusCircleFill } from 'react-icons/bs'
import { LiteralUnion } from 'type-fest'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { store } from '~/pages/heineken_template/_private/store'

const classes = {
  Root: styled.div`
    ${flex.h.allCenter};
    width: 100%;
  `,
  Symbol: styled.div`
    flex-basis: 200px;
    text-align: center;
    font-size: 16px;
  `,
  Text: styled.div``,
  Icon: styled.div`
    align-self: flex-end;
  `,
  Button: styled.div`
    ${flex.h.allCenter};
    gap: 8px;
    width: 100%;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #5069ad;
    cursor: pointer;
    user-select: none;

    &:hover {
      transition: 0.3s;
      background-color: #6788e0;
    }

    &:active {
      background-color: #5597fa;
      transition: 0.3s;
    }
  `,
}

const cssset = css``

export const Display = memo<
  ReactProps<{
    groupName: LiteralUnion<
      | 'demo'
      | 'goldbrain_stock_group_1'
      | 'good178_stock_group_1'
      | 'hyt888_stock_group_1'
      | 'hyt888_stock_group_2'
      | 'mvp888_stock_group_1'
      | 'weng888_stock_group_1'
      | 'weng888_stock_group_2'
      | 'winner98_stock_group_1'
      | 'winner98_stock_group_2'
      | 'winner98_stock_group_3',
      string
    >

    maxSize?: number

    /** # 結尾附加文字 */
    endText?: string
  }>
>(function Display(props) {
  const {
    state: { symbolListArray: watchList },
    acts,
  } = useSymbolWatchList(props.groupName)
  const symbolString = useSnapshot(store.charting).symbol
  const hadSymbol = watchList?.includes(symbolString)
  const icon = hadSymbol ? <BsCheckCircle /> : <BsFillPlusCircleFill />

  return (
    <classes.Root
      className={props.className}
      css={cssset}
    >
      <classes.Symbol>
        <SymbolName symbol={symbolString} />
      </classes.Symbol>

      <classes.Button
        onClick={event => {
          if (hadSymbol) {
            acts.removeSymbol(symbolString)
          }
          if (!hadSymbol) {
            acts.addSymbol(symbolString, props.maxSize ?? 10000)
          }
        }}
      >
        <classes.Icon>{icon}</classes.Icon>
        <classes.Text>
          {hadSymbol ? '已加入' : '加入自選股'}
          {props.endText}
        </classes.Text>
      </classes.Button>
    </classes.Root>
  )
})

export default {
  Display,
  classes,
}
