import { Howl, Howler, HowlOptions } from 'howler'
import { isString } from 'lodash'
import debounce from 'lodash/debounce'
import { apirc } from '~/configs/apirc'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { createStore } from '~/store/createStore'
import { __TEST__ } from '~/utils/__TEST__'

const SECONDS_OF_DEBOUNCE = 0.5

Howler.autoSuspend = false

type UseSoundStoreState = {
  /**
   * 聲音播放開關
   *
   * - 聲音Store的「播放聲音功能」之整體開關
   *
   * @example <caption>開啟/關閉</caption>
   *   useSoundStore.setState({ soundsEnabled: true })
   *   useSoundStore.setState({ soundsEnabled: false })
   *
   * @example <caption>取值</caption>
   *   const soundsEnabled = useSoundStore(state => state.soundsEnabled)
   */
  soundsEnabled: boolean
  playSoundPositiveDebounced(): void
  playSoundNegativeDebounced(): void
  playSoundCoinDebounced(): void
  playSoundVictoryDebounced(): void
  playSoundVictoryLoopDebounced(): void
  playSoundHornDebounced(): void
  playSoundHornLoopDebounced(): void
  playSoundStartCrankingDebounced(): void
  playSoundClearFreshDebounced(): void
  playSoundMagicDebounced(): void
  playSoundAsiajye1Debounced(): void
  playSoundAsiajye2Debounced(): void
  playSoundWinnerLongDebounced(): void
  playSoundWinnerShortDebounced(): void
  playSoundWin168LongDebounced(): void
  playSoundWin168ShortDebounced(): void
  playSoundWin168ExitDebounced(): void
}

export const useSoundStore = createStore<UseSoundStoreState>((set, get) => {
  const soundsEnabledCheck = () => {
    const enabled = get().soundsEnabled
    debugAPI.useSoundStore.log('聲音播放', enabled)
    return enabled
  }

  /** 透過閉包儲存 Sounds,mp3 的 instances 以至於多次播放不會多次載入 mp3 */
  const soundsClosure = () => {
    function soundPositivePlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/jingle-bells-sms-523.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundNegativePlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/consequence-544.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundCoinPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/coin.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundVictoryPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/victory.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundVictoryLoopPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/victory-loop.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundHornPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/horn.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundHornLoopPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/horn-loop.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundStartCrankingPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/start-cranking.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundClearFreshPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/clear-fresh.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundMagicPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/magic.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundAaiajye1Play() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/asiajye1.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundAaiajye2Play() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/asiajye2.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }
    function soundWinnerLongPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/winner-long.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }

    function soundWinnerShortPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/winner-short.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }

    function soundWin168LongPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/win168-long.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }

    function soundWin168ShortPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/win168-short.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }

    function soundWin168ExitPlay() {
      if (!soundsEnabledCheck()) return
      const howlInstance = new Howl({
        src: ['/sounds/win168-exit.mp3'],
        onplayerror(...args) {
          replayOnce.call(this, howlInstance, ...args)
        },
      })

      howlInstance.play()
    }

    return {
      playSoundPositiveDebounced: debounce(() => {
        soundPositivePlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundNegativeDebounced: debounce(() => {
        soundNegativePlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundCoinDebounced: debounce(() => {
        soundCoinPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundVictoryDebounced: debounce(() => {
        soundVictoryPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundVictoryLoopDebounced: debounce(() => {
        soundVictoryLoopPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundHornDebounced: debounce(() => {
        soundHornPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundHornLoopDebounced: debounce(() => {
        soundHornLoopPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundStartCrankingDebounced: debounce(() => {
        soundStartCrankingPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundClearFreshDebounced: debounce(() => {
        soundClearFreshPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundMagicDebounced: debounce(() => {
        soundMagicPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundAsiajye1Debounced: debounce(() => {
        soundAaiajye1Play()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundAsiajye2Debounced: debounce(() => {
        soundAaiajye2Play()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundWinnerLongDebounced: debounce(() => {
        soundWinnerLongPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundWinnerShortDebounced: debounce(() => {
        soundWinnerShortPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundWin168LongDebounced: debounce(() => {
        soundWin168LongPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundWin168ShortDebounced: debounce(() => {
        soundWin168ShortPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
      playSoundWin168ExitDebounced: debounce(() => {
        soundWin168ExitPlay()
      }, SECONDS_OF_DEBOUNCE * 1000),
    }
  }

  return {
    soundsEnabled: true,
    ...soundsClosure(),
  }
})

function replayOnce(this: HowlOptions, soundInstance: Howl, soundId: number, error: AnyExplicit) {
  debugAPI.useSoundStore.log(`觸發重播聲音(${this.src})`, `[ERROR] ${error?.message}`)

  apirc.elk.sendPlayingSoundError(error, {
    failedFileSrc: isString(this.src) ? this.src : this.src.toString(),
  })

  soundInstance.once('unlock', function () {
    soundInstance.play()
  })
}

/* istanbul ignore next */
if (__TEST__) {
  setInterval(() => {
    const state = useSoundStore.getState()

    state.playSoundClearFreshDebounced()
  }, 3000)
}
