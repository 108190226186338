import { css } from '@emotion/react'
import { Modal } from '@mantine/core'
import { proxy, ref, useSnapshot } from 'valtio'
import { component } from '~/utils/component'

export class FuiModals<Name extends string> {
  store

  constructor(names: Name[]) {
    this.store = proxy({
      opened: '' as Name | '',
      names,
    })
  }

  open(name: Name) {
    this.store.opened = name
  }

  close() {
    this.store.opened = ''
  }

  WithModalContent = ref(
    component<
      ReactProps<{
        for: Name
        cssset?: CSS
        blur?: number
      }>
    >(
      props => {
        const state = useSnapshot(this.store)
        const blur = props.blur ?? 3

        return (
          <Modal
            withCloseButton={false}
            opened={state.opened === props.for}
            onClose={() => {
              this.store.opened = ''
            }}
            css={css`
              &.mantine-Modal-root .mantine-Modal-modal {
                ${props.cssset};
              }
            `}
            centered
            overlayOpacity={0.5}
            overlayBlur={blur}
          >
            {props.children}
          </Modal>
        )
      },
      {
        displayName: '彈出窗口',
      },
    ),
  )
}
